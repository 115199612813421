$mobile: 800px;
$tablet: 1368px;

div {
  display: flex;
  box-sizing: border-box;
}
html,
body {
  overflow-x: hidden;
}
.App {
  width: 100%;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  background-image: url("./assets/images/vafaArka.jpg");
  flex-direction: column;
  Link {
    cursor: pointer;
  }
  .nav-bar {
    @media screen and (max-width: $mobile) {
      flex-direction: column;
      background-color: #000;
      z-index: 3;
    }
    .nav-bar-desktop {
      width: 100%;
      height: 100px;
      //     background-color: black;
      justify-content: space-around;
      align-items: center;
      overflow: hidden;
      z-index: 3;
      img {
        width: 70px;
        height: 30px;
        @media screen and (max-width: $mobile) {
          margin-top: 5%;
        }
      }
      .vafa-logo {
        height: auto;
      }
      .nav-bar-items {
        width: 50%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 12px;
        @media screen and (max-width: $mobile) {
          display: none;
        }
      }
      .nav-bar-menu-button {
        display: none;
        @media screen and (max-width: $mobile) {
          display: flex;
          margin-left: 15%;
        }
      }
      a {
        color: aliceblue;
        font-size: 18px;
      }
      .nav-bar-dropdown {
        background-color: black;
        color: #fff;
        margin-right: 10%;
        font-weight: 500;
        @media screen and (max-width: $mobile) {
          display: none;
        }
      }
      .header-vizyon-logo {
        width: 250px;
        height: auto;
        margin-right: 30px;
        @media screen and (max-width: $mobile) {
          width: 80px;
          height: auto;
          margin-right: 20px;
        }
      }
      .header-cam-logo {
        width: 35px;
        height: auto;
        @media screen and (max-width: $mobile) {
          width: 20px;
          height: auto;
        }
      }
    }
    .nav-bar-mobile {
      display: none;
      @media screen and (max-width: $mobile) {
        display: flex;
        padding-bottom: 20px;
      }
      .nav-bar-dropdown-main-mobile {
        display: flex;
        width: 100%;
        justify-content: center;
        .nav-bar-dropdown-mobile {
          text-align: center;
          background-color: transparent;
          color: #fff;
          font-weight: 500;
          width: 15%;
        }
      }
      .nav-bar-mobile-items {
        width: 100%;
        height: 300px;
        display: flex;
        flex-direction: column;
        text-align: center;
        margin: auto;
        justify-content: space-around;
        a {
          text-decoration: none;
          color: #fff;
        }
      }
      .nav-bar-mobile-items-close {
        width: 100%;
        height: 300px;
        display: flex;
        flex-direction: column;
        text-align: center;
        margin: auto;
        justify-content: space-around;
        display: none;
        a {
          text-decoration: none;
          color: #fff;
        }
      }
    }
  }
  .popup {
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Siyah yarı şeffaf arka plan */
    z-index: 1;
  }

  .popup-content {
    position: relative;
    z-index: 2; /* Overlay'den üstte olmalı */
    background-color: #fff;
    padding: 35px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 610px;
    max-height: 610px;
    overflow: auto; /* Gerektiğinde kaydırma çubuklarını görüntüle */
    @media screen and (max-width: 600px) {
      position: relative;
      z-index: 2; /* Overlay'den üstte olmalı */
      background-color: #fff;
      padding: 35px;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      max-width: 300px;
      max-height: 300px;
      overflow: auto; /* Gerektiğinde kaydırma çubuklarını görüntüle */
    }
  }

  .popup-image {
    max-width: 100%;
    max-height: 100%;
    display: block; /* Resim etrafındaki beyaz alanı kaldırmak için */
    margin: 0 auto; /* Resmi merkezlemek için */
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    background: none;
    border: none;
    color: #333; /* Kapat butonunun rengini ayarlayabilirsiniz */
  }

  .camFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $mobile) {
      font-size: 2.6vw;
    }
    a {
      color: white;
    }
  }
  .camFooter1 {
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $mobile) {
      font-size: 2.6vw;
    }
    a {
      color: red;
    }
  }
  .camFooter2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3%;
    @media screen and (max-width: $mobile) {
      font-size: 2vw;
    }
    a {
      color: #e3b009;
    }
  }

  .home-container {
    flex-direction: column;
    .home-top-image {
      width: 100%;
      img {
        width: 100%;
        height: 95%;
        z-index: 1;
      }
    }
    .vafa-education {
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .education-top {
        align-items: center;
        justify-content: center;
        color: aliceblue;
        margin-top: -12px;
        img {
          width: 50%;
        }
        p {
          position: absolute;
        }
      }
      .education-bot {
        width: 100%;
        flex-direction: row;
        border-bottom: 1px solid #b8ce0f;
        @media screen and (max-width: $mobile) {
          flex-direction: column;
        }
        .education-bot-left {
          width: 50%;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 5%;
          @media screen and (max-width: $mobile) {
            width: 100%;
          }
          button {
            width: 150px;
            background-color: #b8ce0f;
            border-radius: 12px;
            height: 28px;
            margin-top: 5%;
            margin-bottom: 5%;
          }
          .education-frame {
            flex-direction: column;
            text-align: center;
            padding: 0 4%;

            justify-content: center;
            width: 40%;
            height: 120px;
            border: 2px solid #b8ce0f;
            border-radius: 4px;
            align-items: center;
            h1 {
              font-size: 1em;
            }
            h5 {
              font-size: 16px;
            }
            @media screen and (max-width: $mobile) {
              width: 80%;
              height: 200px;
            }
          }
        }
      }
    }
    .vafa-academia h5 {
      width: 80%;
      line-height: 25px;
      white-space: pre-line;
      text-align: center;
      align-items: center;
      font-size: medium;
   
      font-weight: bold;
    }

    .vafa-academia {
      max-width: 2560px;
      width: 100%;
      flex-direction: column;
      color: #ffffff;
      .academia-top {
        flex-direction: column;
        align-items: center;
        h1 {
          font-size: 3rem;
          letter-spacing: 1rem;
          margin-top: 3%;
          @media screen and (max-width: $mobile) {
            font-size: 5vw;
            letter-spacing: 1.5vw;
          }
        }
        p {
          color: #bbbbbb;
          margin-top: 2%;
          @media screen and (max-width: $mobile) {
            font-size: 6vw;
            margin-left: 10%;
            margin-right: 10%;
          }
        }
        button {
          font-size: 1.2rem;
          width: 150px;
          background-color: #b8ce0f;
          height: 30px;
          margin-top: 4%;
          border-radius: 4px;
        }
      }
      .academia-bottom {
        align-items: center;
        margin: auto;
        margin-top: 10%;
        width: 90%;
        align-items: first baseline;
        @media screen and (max-width: $mobile) {
          margin-bottom: 20%;
          margin-top: 20%;
        }
        img {
          width: 70%;
          margin: auto;
        }
        .human {
          width: 7%;
          position: absolute;
          margin-left: 70%;
          margin-top: -5%;
          margin-bottom: 5%;
        }
        .frame1 {
          position: absolute;
          flex-direction: column;
          width: 14vw;
          background-color: black;
          height: auto;
          border-radius: 50px;
          align-items: center;
          padding-bottom: 2%;
          text-align: center;
          color: #b8ce0f;
          margin-left: 35%;
          margin-top: -5%;
          h1 {
            font-size: 1.2rem;
            @media screen and (max-width: $mobile) {
              width: 200%;
              font-size: 10px;
            }
          }
          button {
            margin-top: 10%;
            width: 7vw;
            background-color: #b8ce0f;
            color: black;
            height: 30px;
            @media screen and (max-width: $mobile) {
              width: 100%;
              font-size: 10px;
              margin-top: 2%;
              margin-right: 30px;
            }
          }
        }
        .frame2 {
          position: absolute;
          flex-direction: column;
          width: 14vw;
          background-color: rgb(0, 0, 0);
          height: auto;
          border-radius: 50px;
          align-items: center;
          padding-bottom: 2%;
          text-align: center;
          color: #b8ce0f;
          margin-left: 5%;
          margin-top: 12%;
          h1 {
            font-size: 1.2rem;
            @media screen and (max-width: $mobile) {
              width: 200%;
              font-size: 10px;
            }
          }
          button {
            margin-top: 10%;
            width: 7vw;
            background-color: #b8ce0f;
            color: black;
            height: 30px;
            @media screen and (max-width: $mobile) {
              width: 100%;
              font-size: 10px;
              margin-top: 2%;
              margin-right: 30px;
            }
          }
        }
        .frame3 {
          position: absolute;
          flex-direction: column;
          width: 14vw;
          background-color: black;
          height: auto;
          border-radius: 50px;
          align-items: center;
          padding-bottom: 2%;
          text-align: center;
          color: #b8ce0f;
          margin-left: 55%;
          margin-top: 16%;

          h1 {
            font-size: 1.2rem;
            @media screen and (max-width: $mobile) {
              width: 200%;
              font-size: 10px;
            }
          }
          button {
            margin-top: 10%;
            width: 7vw;
            background-color: #b8ce0f;
            color: black;
            height: 30px;
            @media screen and (max-width: $mobile) {
              width: 100%;
              font-size: 10px;
              margin-top: 2%;
              margin-right: 30px;
            }
          }
        }
        .frame4 {
          position: absolute;
          flex-direction: column;
          width: 14vw;
          background-color: black;
          height: auto;
          border-radius: 50px;
          align-items: center;
          padding-bottom: 2%;
          text-align: center;
          color: #b8ce0f;
          margin-left: 55%;
          margin-top: 36%;

          h1 {
            font-size: 1.2rem;
            @media screen and (max-width: $mobile) {
              width: 200%;
              font-size: 10px;
            }
          }
          button {
            margin-top: 10%;
            width: 7vw;
            background-color: #b8ce0f;
            color: black;
            height: 30px;
            @media screen and (max-width: $mobile) {
              width: 100%;
              font-size: 10px;
              margin-top: 2%;
              margin-right: 30px;
            }
          }
        }
        .frame5 {
          position: absolute;
          flex-direction: column;
          width: 14vw;
          background-color: black;
          height: auto;
          border-radius: 50px;
          align-items: center;

          padding-bottom: 2%;
          text-align: center;
          color: #b8ce0f;
          margin-left: 9%;
          margin-top: 49%;

          h1 {
            font-size: 1.2rem;
            @media screen and (max-width: $mobile) {
              width: 200%;
              font-size: 10px;
            }
          }
          button {
            margin-top: 10%;
            width: 7vw;
            background-color: #b8ce0f;
            color: black;
            height: 30px;
            @media screen and (max-width: $mobile) {
              width: 100%;
              font-size: 10px;
              margin-top: 2%;
              margin-right: 30px;
            }
          }
        }
        .frame6 {
          position: absolute;
          flex-direction: column;
          width: 14vw;
          background-color: black;
          height: auto;
          border-radius: 50px;
          align-items: center;
          padding-bottom: 2%;
          text-align: center;
          color: #b8ce0f;
          margin-left: 64%;
          margin-top: 64%;

          h1 {
            font-size: 1.2rem;
            @media screen and (max-width: $mobile) {
              width: 200%;
              font-size: 10px;
            }
          }
          button {
            margin-top: 10%;
            width: 7vw;
            background-color: #b8ce0f;
            color: black;
            height: 30px;
            @media screen and (max-width: $mobile) {
              width: 100%;
              font-size: 10px;
              margin-top: 2%;
              margin-right: 30px;
            }
          }
        }
        .frame7 {
          position: absolute;
          flex-direction: column;
          width: 14vw;
          background-color: black;
          height: auto;
          border-radius: 50px;
          align-items: center;
          padding-bottom: 2%;
          text-align: center;
          color: #b8ce0f;
          margin-left: 40%;
          margin-top: 74%;

          h1 {
            font-size: 1.2rem;
            @media screen and (max-width: $mobile) {
              width: 200%;
              font-size: 10px;
            }
          }
          button {
            margin-top: 10%;
            width: 7vw;
            background-color: #b8ce0f;
            color: black;
            height: 30px;
            @media screen and (max-width: $mobile) {
              width: 100%;
              font-size: 10px;
              margin-top: 2%;
              margin-right: 30px;
            }
          }
        }

        .pilot {
          position: absolute;
          width: 60px;
          margin-top: 90%;
          margin-left: 22%;
        }
      }
    }
    .vafa-plus {
      width: 100%;
      background-image: url("./assets/images/plusphoto.jpg");
      height: auto;
      flex-direction: column;
      text-align: center;
      align-items: center;
      margin-top: 5%;
      border-top: 2px solid #b8ce0f;
      background-repeat: no-repeat;
      background-position: center;

      border-bottom: 2px solid #b8ce0f;
      h1 {
        font-size: 1rem;
        letter-spacing: 1rem;
        margin-top: 3%;
        color: #ffffff;
        margin-top: 5%;
        font-weight: 200;
        @media screen and (max-width: $mobile) {
          font-size: 5vw;
          letter-spacing: 2vw;
          margin-bottom: -5%;
        }
      }
      .extras {
        margin-top: 8%;
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-bottom: 10%;
        @media screen and (max-width: $mobile) {
          flex-direction: column;
        }
        .extra {
          border: 2px solid #b8ce0f;
          width: 25%;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          color: #ffffff;
          letter-spacing: 0.5rem;
          padding: 3%;
          max-width: 300px;
          justify-content: space-around;
          @media screen and (max-width: $mobile) {
            width: 100%;
            margin: auto;
            margin-top: 10%;
          }
          h3 {
            font-size: 0.7rem;
          }

          .extraImg {
            height: 55%;
          }
          button {
            width: 150px;
            height: 30px;
            background-color: #b8ce0f;
            border-radius: 20px;
          }
        }
      }
    }
    .vafa-plus1 {
      width: 100%;
      // background-image: url("./assets/images/plusphoto.jpg");
      height: auto;
      flex-direction: column;
      text-align: center;
      align-items: center;
      margin-top: 5%;
      border-top: 2px solid #b8ce0f;
      background-repeat: no-repeat;
      background-position: center;

      border-bottom: 2px solid #b8ce0f;
      h1 {
        font-size: 1rem;
        letter-spacing: 1rem;
        margin-top: 3%;
        color: #ffffff;
        margin-top: 5%;
        font-weight: 200;
        @media screen and (max-width: $mobile) {
          font-size: 5vw;
          letter-spacing: 2vw;
          margin-bottom: -5%;
        }
      }
      .extras {
        margin-top: 8%;
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-bottom: 10%;
        @media screen and (max-width: $mobile) {
          flex-direction: column;
        }
        .extra {
          border: 2px solid #b8ce0f;
          width: 25%;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          color: #ffffff;
          letter-spacing: 0.5rem;
          padding: 3%;
          max-width: 300px;
          justify-content: space-around;
          @media screen and (max-width: $mobile) {
            width: 100%;
            margin: auto;
            margin-top: 10%;
          }
          h3 {
            font-size: 0.7rem;
            line-height: 1.6em;
            margin-bottom: 1.5vw;
            font-weight: bold;
          }

          .extraImg {
            height: 55%;
          }
          button {
            width: 150px;
            height: 30px;
            background-color: #b8ce0f;
            border-radius: 20px;
          }
        }
      }
    }
    .filoCon {
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .filo {
        background-image: url("./assets/images/filoBack.jpg");
        height: 45vh;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
      }
      h1 {
        letter-spacing: 1rem;
        color: #ffffff;
      }
      .planes {
        flex-direction: row;
        flex-wrap: wrap;
        width: 80%;
        align-items: center;
        justify-content: space-around;
        margin-top: 5%;
        @media screen and (max-width: $mobile) {
          flex-direction: column;
        }
        .plane {
          flex-direction: column;
          width: 30%;
          color: #ffffff;
          align-items: center;
          margin-top: 5%;
          @media screen and (max-width: $mobile) {
            width: 100%;
            margin-top: 10%;
          }
          img {
            width: 20vw;
            height: 15vh;
            @media screen and (max-width: $mobile) {
              width: 70%;
            }
          }
          button {
            width: 140px;
            height: 40px;
            background-color: black;
            color: #ffffff;
            border: 2px solid #ffffff;
            border-radius: 20px;
          }
        }
      }
    }
    .blog {
      margin: auto;
      width: 60vw;
      height: auto;
      border: 2px solid #b8ce0f;
      margin-top: 9%;
      border-radius: 22px;
      @media screen and (max-width: $mobile) {
        width: 80%;
      }
      align-items: center;
      flex-direction: column;
      padding: 5% 0;
      h1 {
        color: #ffffff;
        letter-spacing: 0.7rem;
        margin-top: -5%;
        font-size: 14px;
        margin-bottom: 2%;
        span {
          color: #b8ce0f;
          font-weight: bolder;
        }
      }
      .blogCon {
        width: 40vw;
        height: auto;
        background-image: url(./assets/images/blogbg.jpg);
        background-position: center;
        @media screen and (max-width: $mobile) {
          width: 80%;
        }
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 5% 0;

        h2 {
          color: #ffffff;
          width: 50%;
          font-size: 14px;
          font-weight: 600;
        }
        img {
          width: 80%;
          height: 5px;
          margin: 7%;
        }
      }
      button {
        display: flex;
        background-color: #b8ce0f;
        @media screen and (max-width: $mobile) {
          width: 100%;
          margin-bottom: 10%;
        }
        align-items: center;
        justify-content: space-around;
        text-align: center;
        padding: 0 2%;
        flex-direction: row;
        border-radius: 25px;
        width: 200px;
        height: 50px;
        margin-top: 4%;
        margin-bottom: -6%;
        img {
          margin-right: 2%;
        }
        h6 {
          font-weight: 800;
        }
      }
    }
  }
  .alsimaTit {
    margin-left: 5%;
    margin-right: 5%;
    p {
      font-size: 1.3vw !important;
    }
  }
  .alsimImage {
    display: flex;
    flex-wrap: wrap;
    img {
      width: 30% !important;
      height: auto !important;
    }
  }
  .footer {
    color: white;
    width: 100%;
    margin-top: 5%;
    flex-direction: column;
    .footer-top {
      flex-direction: row;
      @media screen and (max-width: $mobile) {
        flex-direction: column;
      }
      width: 100%;
      h2 {
        align-self: center;
        justify-self: center;
        letter-spacing: 0.5rem;
        margin: auto;
        @media screen and (max-width: $mobile) {
          margin-top: 5%;
        }
      }
      .top-left {
        display: flex;
        flex-direction: column;
        h3 {
          margin-left: 55%;
          @media screen and (max-width: $mobile) {
            margin: auto;
            margin-bottom: 5%;
          }
        }
      }
    }
    .footerMid {
      align-items: center;
      justify-content: space-around;
      margin-top: 10%;
      @media screen and (max-width: $mobile) {
        flex-direction: column;
        width: 90%;
        margin: auto;
      }
      .midItem {
        flex-direction: column;
        align-items: center;
        width: 25%;
        text-align: center;
        @media screen and (max-width: $mobile) {
          width: 90%;
          margin: auto;
        }
      }
    }
    .footerMid2 {
      align-items: center;
      justify-content: space-around;
      width: 50%;
      @media screen and (max-width: $mobile) {
        flex-direction: column;
        width: 90%;
        margin: auto;
      }
      margin: auto;
      margin-top: 2%;
    }
    .footerLinks {
      width: 30%;
      margin: auto;
      justify-content: space-around;
      margin-top: 6%;
      a img {
        width: 50px;
        height: 50px;
      }
      @media screen and (max-width: $mobile) {
        width: 100%;
        justify-content: space-around;
        a img {
          width: 50px;
          height: 50px;
        }
      }
    }
    .footerBottom {
      width: 90%;
      align-items: center;
      justify-content: space-between;
      margin: auto;
      margin-top: 5%;
      @media screen and (max-width: $mobile) {
        margin-bottom: 4%;
        img {
          width: 10%;
        }
        p {
          font-size: 2vw;
        }
      }
    }
  }
  .TopFeatureFl {
    text-transform: uppercase;
    font-size: 2vw !important;
    font-weight: 900 !important;
  }
  .main-con-ppl {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #000;
    object-fit: contain;
    h5 {
      color: #fff;
      margin-left: 10%;
      font-size: 1.2vw;
      @media screen and (max-width: $mobile) {
        font-size: 5vw;
      }
    }
    .ppl-head {
      font-size: 2vw;
      font-weight: 500;
      margin-top: 5%;
      margin-bottom: 5%;
      color: #ffffff;
      margin-left: 10%;
      @media screen and (max-width: $mobile) {
        font-size: 5vw;
      }
    }
    .ppl-exp {
      display: flex;
      max-width: 870px;
      width: 100%;
      color: #ffffff;
      margin-left: 5%;
      margin-top: 2%;
      @media screen and (max-width: $mobile) {
        width: 80%;
      }
      .exp-img-con {
        margin-right: 4%;
        margin-left: 4%;
        img {
          margin-top: 25%;
          height: 80px;
          width: auto;
          @media screen and (max-width: $mobile) {
            height: 20px;
          }
        }
      }
      .exp-main-con {
        display: flex;
        flex-direction: column;
        h1 {
          font-weight: 400;
          font-size: 1.5vw;
          margin-bottom: 1%;
          @media screen and (max-width: $mobile) {
            font-size: 3vw;
          }
        }
        p {
          margin-top: 0.5%;
          font-weight: 300;
          font-size: 1vw;
          line-height: 2vw;
          @media screen and (max-width: $mobile) {
            font-size: 3vw;
            line-height: 5vw;
          }
        }
      }
    }
  }
  .services-main-con {
    display: flex;
    flex-direction: column;
    .service-img-exp-con {
      display: flex;
      flex-direction: row;
      @media screen and (max-width: $mobile) {
        flex-direction: column;
      }
      .services-img-con {
        width: 50%;
        @media screen and (max-width: $mobile) {
          width: 100%;
        }
        img {
          width: 100%;
        }
      }
      .services-exp-con {
        background-image: url("./assets/images/serviceline.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        width: 40%;
        margin: auto;
        @media screen and (max-width: $mobile) {
          width: 90%;
          margin-top: 10%;
          background-image: none;
        }
        .service-exp-main {
          max-width: 800px;
          display: flex;
          flex-direction: column;
          margin: auto;
          color: white;
          .service-exp-head {
            margin-left: 10%;
            h1 {
              margin: auto;
              margin-bottom: 2%;
              @media screen and (max-width: $mobile) {
                font-size: 6vw;
              }
            }
          }
          .service-exp-log {
            img {
              width: 70px;
              height: 70px;
              margin-left: 2%;
              margin-right: -5%;
              margin-top: -3%;
              @media screen and (max-width: $mobile) {
                width: 30px;
                height: 30px;
                margin-top: 2%;
              }
            }
            p {
              font-size: 24px;
              margin: 10%;
              margin-top: 1%;
              margin-bottom: 2%;
              line-height: 32px;
              @media screen and (max-width: $mobile) {
                font-size: 4vw;
                line-height: 4vw;
              }
            }
          }
        }
      }
    }
  }
  .fleet-main {
    display: flex;
    flex-direction: column;
    color: #fff;
    .fleet-exp-con {
      display: flex;
      flex-direction: column;
      text-align: center;
      h1 {
        font-size: 3vw;
        font-weight: 400;
        @media screen and (max-width: $mobile) {
          font-size: 8vw;
          margin: auto;
          margin-top: 10%;
          width: 90%;
        }
      }
      img {
        width: 40%;
        margin: auto;
        @media screen and (max-width: $mobile) {
          width: 90%;
          margin-top: 10%;
        }
      }
      p {
        font-size: 1.8vw;
        line-height: 5vw;
        font-weight: 200;
        @media screen and (max-width: $mobile) {
          margin: auto;
          margin-top: 10%;
          width: 90%;
          font-size: 4vw;
          line-height: 10vw;
        }
      }
    }
  }
  .graduates {
    display: flex;
    flex-direction: column;

    .graduates-main-con {
      display: flex;
      flex-direction: column;
      .graduates-head {
        display: flex;
        text-align: center;
        margin: auto;
        color: #fff;
        margin-top: 5%;

        h1 {
          font-size: 32px;
          font-weight: 400;
          letter-spacing: 10px;
          @media screen and (max-width: $mobile) {
            width: 90%;
            margin: auto;
            margin-bottom: 10%;
            margin-top: 10%;
            font-size: 5vw;
            letter-spacing: 3vw;
          }
        }
      }
      .graduates-body {
        justify-content: space-between;
        margin-bottom: 10%;
        @media screen and (max-width: $mobile) {
          flex-direction: column;
        }
        .graduate-left {
          display: flex;
          flex-direction: column;
          width: 40%;
          @media screen and (max-width: $mobile) {
            width: 100%;
          }
        }
        .graduate-mid {
          @media screen and (max-width: $mobile) {
            display: none;
          }
          display: flex;
          flex-direction: column;
          width: 10%;
          margin-top: 4%;
          img {
            width: 3px;
            height: 100%;
            margin: auto;
          }
        }
        .graduate-right {
          display: flex;
          flex-direction: column;
          width: 40%;
          @media screen and (max-width: $mobile) {
            width: 100%;
          }
        }
      }
    }
  }
  .graduates-card {
    display: flex;
    flex-direction: column;
    margin-top: 20%;
    img {
      width: 100%;
    }
    .graduates-card-info {
      width: 90%;
      margin: auto;
      align-items: center;
      margin-top: 2%;
      h5 {
        font-size: 16px;
        color: #fff;
        margin: auto;
        width: 25%;
        @media screen and (max-width: $mobile) {
          font-size: 3.5vw;
        }
      }
      img {
        margin-left: 2%;
        margin-right: 2%;
        height: 3px;
        width: 5%;
      }
    }
    h1 {
      text-align: center;
      color: #fff;
      font-weight: 300;
    }
    p {
      color: #fff;
      text-align: center;
      font-weight: 100;
      line-height: 24px;
      margin-left: 2%;
      margin-right: 2%;
    }
  }
  .bloghome {
    flex-direction: column;
    .bloghome-main-con {
      flex-direction: column;
      .bloghome-hr-con {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 5%;
        @media screen and (max-width: $mobile) {
          flex-direction: column;
          width: 100%;
        }
      }
    }
  }
  .blog-card {
    width: 33%;
    flex-direction: column;
    @media screen and (max-width: $mobile) {
      width: 100%;
    }
    .blog-card-head {
      height: 10%;
      justify-content: space-between;
      img {
        align-self: center;
        width: 25%;
        height: 3px;
      }
      h1 {
        width: 70%;
        font-size: 20px;
        font-weight: 400;
        color: #fff;
        letter-spacing: 3px;
      }
    }
    .blog-card-cont-date {
      justify-content: space-between;
      margin-top: 5%;
      margin-bottom: 5%;
      margin-left: 1%;
      .cont-date-logo {
        align-items: center;
        a {
          margin-left: 10%;
        }
      }
      .cont-date-con {
        border: #fff 1px solid;
        padding: 10px 20px;
        p {
          color: #fff;
          margin: 0;
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
    .blog-card-exp {
      height: 50%;
      margin-left: 2%;
      flex-direction: column;
      p {
        color: #fff;
        line-height: 22px;
        font-weight: 300;
      }
      a {
        text-decoration: none;
        p {
          display: inline-flex;
          border: #b8ce0f 1px solid;
          padding: 5px 20px;
        }
      }
    }
    .blog-card-foot {
      justify-content: space-between;
      img {
        align-self: center;
        width: 75%;
        height: 2px;
        @media screen and (max-width: $mobile) {
          width: 60%;
        }
      }
      p {
        display: inline-flex;
        color: #fff;
        font-size: 12px;
        @media screen and (max-width: $mobile) {
          margin-right: 4%;
        }
      }
    }
  }
  .blogs {
    flex-direction: column;
    .blogs-main {
      flex-direction: column;
      background-color: #292929;
      .blogs-head {
        margin: auto;
        color: #fff;
        text-align: center;
        margin-top: 2%;
        margin-bottom: 2%;
        h1 {
          font-weight: 600;
          letter-spacing: 5px;
          @media screen and (max-width: $mobile) {
            font-size: 4vw;
          }
        }
      }
      .blogs-exp {
        width: 95%;
        margin: auto;
        margin-top: 4%;
        margin-bottom: 10%;
        p {
          color: #fff;
          line-height: 30px;
        }
      }
    }
  }
  .pricing {
    flex-direction: column;
    .pricing-main-con {
      flex-direction: column;
      .pricing-two-hori {
        margin-top: 10%;
        width: 100%;
        justify-content: space-around;
        @media screen and (max-width: $mobile) {
          flex-direction: column;
          margin-top: 0%;
          align-items: center;
        }
      }
    }
  }
  .pricing-card {
    width: 40%;
    flex-direction: column;
    color: #fff;
    @media screen and (max-width: $mobile) {
      width: 90%;
      margin-top: 10%;
    }
    .pricing-card-header {
      margin: auto;
      font-size: 0.8vw;
      margin-bottom: 5%;
      @media screen and (max-width: $mobile) {
        font-size: 1.5vw;
      }
    }
    .pricing-card-body {
      border: #b8ce0f solid 2px;
      border-radius: 20px;
      .pricing-card-body-left {
        align-items: center;
        width: 100%;
        flex-direction: column;
        padding: 5%;
      }
    }
  }
  .loader {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .whatsapp-button {
    position: fixed;
    bottom: 20px; /* İstediğiniz boşluk miktarını ayarlayın */
    right: 20px; /* İstediğiniz boşluk miktarını ayarlayın */
    z-index: 9999; /* Diğer öğelerin üzerinde görüntülenmesini sağlamak için yüksek bir z-index değeri belirleyin */
    img {
      width: 80px;
      @media screen and (max-width: $mobile) {
        width: 35px;
      }
    }
  }

  .vafa-tagline {
    z-index: 2;
    color: black;
    position: absolute;
    font-weight: bold;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90%; /* Genişlik için maksimum yüzde değeri */
    font-size: 2.5vw; /* Metin boyutu için vw (viewport width) birimi */
    text-align: center; /* Metni yatay olarak ortala */
    background-color: rgba(184, 206, 15, 0.9);
    line-height: 1.5em;
    width: 80%;

    @media screen and (max-width: $mobile) {
      font-size: 2vw;
      top: 20%;
    }
  }

  .gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Resimler arasındaki boşluk */
  }

  .gallery-item {
    flex-basis: calc(
      33.33% - 20px
    ); /* Resimlerin %33 genişliği ve aralarında 20px boşluk */
    margin-bottom: 20px; /* Altta 20px boşluk */
    @media screen and (max-width: $mobile) {
      flex-basis: calc(50% - 20px);
    }
  }

  .gallery-item img {
    width: 100%;
    height: auto;
    display: block;
  }

  .gallery-container {
    text-align: center; /* Yazının ortalanması için */
  }

  .gallery-title {
    margin-top: 4%;
    margin-bottom: 5%; /* Yazının altında 20px boşluk bırak */
    font-size: 30px; /* Yazı boyutu */
    font-weight: bold; /* Kalın yazı */
    color: white; /* Yazı rengi */
    text-align: center; /* Yazıyı ortala */
    letter-spacing: 1.7vw;
  }

  .video-container {
    width: 70%; /* Video container'ın genişliği */
    max-width: 1800px; /* Video container'ın maksimum genişliği */
    margin: 0 auto; /* Video container'ı ortala */
    margin-bottom: 8%;
  }

  .video-container video {
    width: 100%; /* Video öğesinin genişliği */
    height: auto; /* Video öğesinin yüksekliği, genişlik oranına göre ayarlanır */
    display: block; /* Video öğesini blok olarak görüntüle */
  }

  .full-screen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Yarı saydam siyah bir arka plan */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .full-screen-image {
    position: relative;
    width: 85vw; /* Ekran genişliğinin yarısı */
    height: 85vh; /* Ekran yüksekliğinin yarısı */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); /* Resmin etrafındaki gölge */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .full-screen-image img {
    max-width: 100%; /* Resmin maksimum genişliği */
    max-height: 100%; /* Resmin maksimum yüksekliği */
  }

  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 36px;
    color: white;
    cursor: pointer;
    z-index: 1;
  }

  .arrow-left {
    left: 10px;
  }

  .arrow-right {
    right: 10px;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: rgb(0, 0, 0);
    cursor: pointer;
    z-index: 1;
  }
  .close-button1 {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    z-index: 1;
  }

  .carousel {
    position: relative;
    width: 100vw; /* Tam ekran genişliği */
    height: 56vw; /* Tam ekran yüksekliği */
    overflow: hidden;
    margin-top: -3vw; /* Yatayda ortalama */
    display: flex;
    justify-content: center;
    align-items: center;

    &_slide {
      position: absolute;
      top: 0;
      left: 0;
      max-height: 55vw;
      height: 100%;
      width: 100%;
      text-align: center;
      z-index: 1;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &_history {
      position: absolute;
      z-index: 3; /* Artırıldı */
      bottom: 10px;
      width: 50%;
      left: 50%;
      transform: translateX(-50%);

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        height: 3px;

        li {
          width: 15%;
          box-sizing: border-box;
          height: inherit;
          position: relative;
          &:not(:last-of-type) {
            margin-right: 5%;
          }

          button {
            border: none;
            outline: none;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(255, 255, 255, 0.5);
            width: 100%;
            height: 100%;
            cursor: pointer;
            transition: background-color 300ms ease;
            &.active {
              background-color: rgba(255, 255, 255, 0.95);
            }
          }
        }
      }
    }

    &_control {
      position: absolute;
      z-index: 3; /* Artırıldı */
      top: 0;
      height: 100%;
      display: block;
      background-color: rgba(0, 0, 0, 0.1);
      border: 0;
      padding: 0 5px;
      width: 10%;
      overflow: hidden;
      transition: background-color 300ms ease;
      cursor: pointer;

      span {
        display: inline-block;
        width: 2vw;
        height: 2vw;
        position: relative;
        box-sizing: border-box;
        opacity: 0.65;
        border-bottom: 3px solid white;
        border-left: 3px solid white;
        transition: opacity 300ms ease;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.2);

        span {
          opacity: 0.95;
        }
      }

      &__prev {
        left: 0;
        padding-left: 12px;
        span {
          transform: rotate(45deg);
        }
      }

      &__next {
        right: 0;
        padding-right: 12px;
        span {
          transform: rotate(-135deg);
        }
      }
    }
  }

  .form-container {
    max-width: 70%; /* Ekran genişliğinin %70'i */
    margin: 0 auto;
  }

  .form-control {
    margin-bottom: 10px; /* 10px boşluk */
  }

  .form-control input,
  .form-control textarea,
  .form-control select {
    width: calc(100% - 20px); /* Genişlik %100, padding 10px her iki tarafta */
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: #000; /* Arka plan şeffaf */
    color: white; /* Yazı rengi beyaz */
  }

  .form-control select {
    height: 40px;
  }

  .form-control textarea {
    height: 150px;
  }

  .form-control button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: #424446;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .form-control button:hover {
    background-color: #0056b3;
  }

  .form-text {
    color: white;
  }


  .container-blog {
    display: flex;
    justify-content: center; /* Yatayda ortala */
    align-items: center; /* Dikeyde ortala */

    img {
      height: auto;
      width: 50vw;
    }

  }

   

  /* Pop-up genel stil */
.custom-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Arka plan için siyah şeffaf overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Pop-up içerik */
.custom-popup-content {
  position: relative;
  width: 600px;
  height: 600px; /* Kare boyut: 600x600 */
  background-color: #fff;
  border-radius: 8px; /* Köşeleri yuvarlatmak için */
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Kapama butonu */
.custom-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(214, 229, 96, 0.9);
  border: none;
  color: #fff;
  font-size: 16px;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.custom-close-button:hover {
  background: rgba(184, 206, 15, 0.9);
}

/* Overlay tıklama alanı */
.custom-popup-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Iframe stil */
.custom-popup-iframe {
  width: 100%;
  height: 100%;
  border: none;
}




}
